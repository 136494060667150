import Dashboard from '@/views/Dashboard';
import withAuth from '@/components/withAuth';
import { restrictRoles } from '@/constants/roleRestrictions';
import { useEffect } from 'react';
import { onPushEvent } from '@/services/lib/gtm';

const DashBoard = () => {
    useEffect(() => {
        onPushEvent('user_visit_dashboard_section');
    }, []);

    return <Dashboard />;
};

export default withAuth(DashBoard, restrictRoles.HOME);
